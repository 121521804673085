import React, { useEffect, useState } from 'react';
import FormEdit from '../../components/EditForm/edit';
import axios from 'axios';
import { rootUrl } from '../../config';
import Loading from "../../components/Loading/Loading";
import "./main.css"
const EditPage = ({match}) => {
    const [shop,setShop]  = useState({});
    const [loading,setLoading]  = useState(false);
    useEffect(async() => {
        const result = await axios.get(`${rootUrl}/shop/${match.params.id}`);
         const shop = result.data;
         setShop(shop);
         setLoading(true);
     }, []);
    return ( <>
    <div className="admin__background">
        <span className="admin__bgfilter"></span>
        <h2>Contribute to the <span>Shop</span></h2>
    </div>
    <div className="admin__formcontainer">{
loading?<FormEdit shop_id={match.params.id} shop={shop}/>:<Loading/>
    }
    </div>
    </> );
}
 
export default EditPage;