import { coordToAddress } from "./geocode";

export const setCurrentLocation = (setCoords) => {
  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const address = await coordToAddress(position.coords);
      setCoords({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        address: address,
      });
    },
    // error
    () => null
  );
};
