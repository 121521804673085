import React, {useRef}  from 'react';
import { useAuth } from '../../contexts/AuthContext';
import "./main.css"
import { useHistory} from 'react-router-dom'


const Login = () => {
    const usernameRef = useRef()
    const passwordRef = useRef()
    const {login, authErr, setAuthErr} = useAuth()
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()
      
        try {
            setAuthErr('')
            await login(usernameRef.current.value, passwordRef.current.value)
            history.push('/')
        } catch (error) {
            console.log(error)
            setAuthErr('Failed to sign in')  
        }
    }

    return ( 
    <>
        <div className="login__container">
            <h2>Admin Login</h2>
            {authErr && <div className='error'>{authErr}</div>}
            <form className="login__form" onSubmit={handleSubmit}>
                <input type='text' id='username' className="login__field" placeholder='Username' ref={usernameRef} />
                <input type='password' id='password' className="login__field" placeholder='Password' ref={passwordRef} />
                <input type='submit' className="login__submit" value='Login'/>
            </form>
        </div>
    </> );
}
 
export default Login;