import React from 'react';
import FormComponent from '../../components/Form';
import "./main.css"
const AdminPage = () => {
    return ( <>
    <div className="admin__background">
        <span className="admin__bgfilter"></span>
        <h2>Contribute to the <span>Shop</span></h2>
    </div>
    <div className="admin__formcontainer">
       <FormComponent/>
    </div>
    </> );
}
 
export default AdminPage;