import axios from "axios";
import { createContext, useState, useEffect, useContext } from "react";
import { rootUrl } from "../config";
import { useHistory } from "react-router-dom";
const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentToken, setCurrentToken] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [authErr, setAuthErr] = useState();
  const history = useHistory();
  function logout() {
    localStorage.removeItem("regioheld-auth-token");
    setCurrentToken(undefined);
  }
  async function login(username, password) {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${rootUrl}/admin/login`, {
        username,
        password,
      });
      console.log("FROM LOGIN FUNCTION");
      setCurrentToken(`Bearer ${data.token}`);
      setIsLoading(true);
    } catch (error) {
      setAuthErr(error.message);
      console.log("ERROR!!");
      console.log(error);
      logout();
    }
  }
  const value = {
    currentToken,
    login,
    logout,
    authErr,
    isLoading,
    setAuthErr,
  };
  useEffect(() => {
    if (currentToken && typeof currentToken != typeof undefined) {
      localStorage.setItem("regioheld-auth-token", currentToken);
      history.push("/");
    } else {
      let localToken = localStorage.getItem("regioheld-auth-token");
      if (localToken) {
        console.log("Retrieveing from local stroage", localToken);
        setCurrentToken(localToken);
      }
    }
  }, [currentToken]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
