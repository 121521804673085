import "./App.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import SearchPage from "./pages/Search";
import Footer from "./components/Footer/Footer";
import AdminPage from "./pages/Admin";
import EditPage from "./pages/EditForm";

import { AuthProvider } from "./contexts/AuthContext";
import { SearchProvider } from "./contexts/SearchContextProvider";
import Login from "./pages/Login";
import PrivateRoute from "./contexts/PrivateRoute";

function App() {
  return (
    <>
      <AuthProvider>
        <SearchProvider>
          <NavBar />
          <div className="main__container">
            <Switch>
              <Route path="/login" exact component={Login} />
              <PrivateRoute path="/edit/:id" component={EditPage} />
              <PrivateRoute path="/shops/search/" component={SearchPage} />
              <PrivateRoute path="/" exact component={AdminPage} />
            </Switch>
          </div>
          <Footer />
        </SearchProvider>
      </AuthProvider>
    </>
  );
}

export default App;
