import React, { useEffect } from 'react';
import { useSearch } from "../../contexts/SearchContextProvider";
import { AiFillRightCircle } from "react-icons/ai";
import { AiFillLeftCircle } from "react-icons/ai";
import "./main.css"
const Paginate = () => {
	
	const {getter, setter} = useSearch();
	useEffect(() => {
		setter.pageNo(1);	
		}, [])
	const handlePrev=()=>{
	
		let temp =parseInt(getter.pageNo);
		if(temp===1)return;
		temp = temp -1;
		setter.pageNo(temp);
	}
	const handleNext=()=>{
		let temp =parseInt(getter.pageNo);
		temp = temp+1;
		setter.pageNo(temp);
	}
	const maxPage = Math.ceil(getter.count/12) ;
	const prevClass = parseInt(getter.pageNo)===1 ? "pagination-number background_none" : "pagination-number";
	const nextClass = parseInt(getter.pageNo)===maxPage ? "pagination-number background_none" : "pagination-number";
	const paginationClass = getter.count ? "md-ui component-pagination " : "md-ui component-pagination background_none";

    return ( <div>
        <ul className={paginationClass}>
	<li className="pagination-arrow arrow-left"  onClick={handlePrev} ><AiFillLeftCircle size="2em"/>
  </li>
	<li className={prevClass}>{parseInt(getter.pageNo)===1?null:parseInt(getter.pageNo)-1}</li>
	<li className="pagination-number current-number">{parseInt(getter.pageNo)}</li>
	<li className={nextClass}>{parseInt(getter.pageNo)===maxPage? null :parseInt(getter.pageNo) + 1}</li>
	<li className="pagination-arrow arrow-right" onClick={handleNext}><AiFillRightCircle size="2em"/></li>
</ul>
    </div> );
}
 
export default Paginate;