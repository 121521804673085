import { createContext, useState, useEffect, useContext } from "react";
import { useQueryAsState } from "use-route-as-state";
import { rootUrl } from "../config";
import getTags from "../utils/getTags";
import axios from "axios";
import { useAuth } from "./AuthContext";
const SearchContext = createContext();
export function useSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [shops, setShops] = useState([]);
  const [{ search, pageNo }, updateQueryParams] = useQueryAsState({
    pageNo: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState();
  const [count, setCount] = useState();
  const [refresh, setRefresh] = useState();
  const { currentToken } = useAuth();
  const getter = {
    search,
    pageNo,
    count,
    isLoading,
  };

  const setter = {
    search: (search) => {
      updateQueryParams({ search });
    },
    pageNo: (pageNo) => {
      updateQueryParams({ pageNo });
    },
    refresh: () => {
      setRefresh({ random: Math.random() });
    },
  };

  let cancelToken;
  const handleSearch = async () => {
    setIsLoading(true);
    setShops([]);

    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    let tags;
    if (search && search.trim().length) {
      tags = getTags(search);
      console.log(tags);
    }
    const body = {
      tags,
      pageNo,
    };

    console.log(body);
    try {
      const results = await axios.post(
        `${rootUrl}/admin/shop-search`,
        body,
        {
          headers: { authorization: currentToken },
          cancelToken: cancelToken.token,
        } //Pass the cancel token to the current request
      );
      setShops(results.data.results);
      setIsLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        setErr(error.message);
        console.log("ERROR!!");
      }
      console.log(error);
    }
  };
  let countCancelToken;
  const handleCount = async () => {
    //Check if there are any previous pending requests
    if (typeof countCancelToken != typeof undefined) {
      countCancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    countCancelToken = axios.CancelToken.source();

    let tags;
    if (search && search.trim().length) {
      tags = getTags(search);
    }
    const body = {
      tags,
      pageNo,
    };

    console.log(body);
    try {
      const results = await axios.post(
        `${rootUrl}/admin/shop-count`,
        body,
        {
          headers: { authorization: currentToken },
          cancelToken: countCancelToken.token,
        } //Pass the cancel token to the current request
      );
      setCount(results.data.totalCount);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setErr(error.message);
        console.log("ERROR!!");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    handleCount();
  }, [search, refresh]);

  useEffect(() => {
    handleSearch();
  }, [search, pageNo, refresh]);

  const value = { err, isLoading, getter, setter, shops };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
