import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import "./main.css";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const NavBar = () => {
  const {logout} = useAuth();
  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/shops/search",
      search: `search=${search}`,
    });
  };
  const handleLogout =()=>{
 logout();
  }
  const history = useHistory();

  const [search, setSearch] = useState("");
  return (
    <div className="navbar__container">
      <div className="logo__container">
        <img src={Logo} alt="RegioHeld_Logo" />
        <h4>
          REGIO<span style={{ color: "#FDBA16" }}>HELD</span>
        </h4>
      </div>
      <div className="searchbar__container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="shop__searchbar"
          />
        </form>
      </div>
      <div onClick={handleLogout} className="logout__button">
        <button>Logout</button>
      </div>
    </div>
  );
};

export default NavBar;
