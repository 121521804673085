import React from "react";
import { BiCurrentLocation } from "react-icons/bi";
import "./main.css";
import { setCurrentLocation } from "../../utils/setCurrentLocation";

const Locate = ({ setCoords }) => {
  return (
    <button
      style={{ display: "block" }}
      className="locate__btn"
      onClick={() => setCurrentLocation(setCoords)}
    >
      <BiCurrentLocation className="current__locationicon" size="2vw" />
    </button>
  );
};

export default Locate;
