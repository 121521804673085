import React from 'react';
import "./main.css"
const Loading = () => {
    return ( <div className="loading__container">
    <div className="loading__bg">

    </div>
    </div> );
}
 
export default Loading;