import React from "react";
import "./main.css";
import { rootUrl } from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSearch } from "../../../contexts/SearchContextProvider";
import { useAuth } from "../../../contexts/AuthContext";

const SearchShop = ({ shop }) => {
  const { setter } = useSearch();
  const { currentToken } = useAuth();
  const handleDelete = () => {
    console.log("deletign");
    axios
      .delete(`${rootUrl}/shop/${shop._id}`, {
        headers: {
          authorization: currentToken,
        },
      })
      .then((res) => {
        setter.refresh();
        console.log(`${rootUrl}/shop/${shop._id}`);
      });
  };

  return (
    <div className="shop__container">
      <div className="shop__image"></div>
      <div className="shop__discription">
        <h1>{shop.name}</h1>
        <p>{shop.address}</p>
        <h4>{shop.about}</h4>
        <Link to={`/edit/${shop._id}`} className="shop__visit">
          Edit Shop
        </Link>
        <button
          style={{ border: "none", cursor: "pointer", outlineWidth: "0px" }}
          onClick={handleDelete}
          className="shop__visit"
        >
          Remove Shop
        </button>
      </div>
    </div>
  );
};

export default SearchShop;
