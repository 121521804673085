import {Route} from 'react-router-dom'
import {useAuth} from './AuthContext'
import {Redirect} from 'react-router-dom'

export default function PrivateRoute({component: Component, ...rest}) {
    const {currentToken} = useAuth()
    return (
        <Route
            {...rest}
            render={props => {
                return currentToken ? <Component {...props} /> : <Redirect to='/login'/>
            }}
        >
        </Route>
    )
}
