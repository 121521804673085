import stopwords from "./stopwords.json";

export default function getTags(query) {
  return query
    .toLowerCase()
    .split(" ")
    .filter((w) => {
      const tag = w.trim();
      if (stopwords["english"].includes(tag)) {
        return false;
      }
      if (stopwords["german"].includes(tag)) {
        return false;
      }
      return true;
    });
}